import PropTypes from 'prop-types'
import React from 'react'
import tw from 'twin.macro'
import { StyleType } from '../utils/prop-types'
import Heading from './heading'
import Image from './image'
import Text from './text'

const Values = ({ values, style, textStyle }) => {
  return (
    <div css={[tw`flex flex-col lg:(flex-row space-y-0 space-x-20)`, style]}>
      {values.map((value, i) => (
        <div key={i} css={tw`flex flex-row text-center flex-auto pt-8 lg:(pt-0 items-center flex-col w-1/4)`}>
          <div css={tw`w-16 h-16 lg:(w-24 h-24)`}>
            <Image image={value.image} style={tw`lg:(w-full h-full)`} />
          </div>
          <div css={[tw`flex flex-col w-8/10 text-left ml-4 grid col-start-2 col-end-4 lg:(w-44 ml-0 h-32) xl:w-64 xxl:w-80`, textStyle]}>
            <Heading
              headingType="h5"
              content={value.title}
              style={tw`font-bold text-primary-500 lg:(mt-12)`}
            />
            <Text content={value.subtitle} style={tw`lg:mt-4`} />
          </div>
        </div>
      ))}
    </div>
  )
}

Values.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
  textStyle: StyleType,
}

export default Values
